/* Youtube Card */

.youtubecardlogo
{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 11.4%;
    margin-bottom: 6%;
    width: 50%;
}

.youtubecard
{
    background-color: rgb(30, 30, 30);
    
    margin:auto;
    height: auto;
    width: 90%;

    border: 2px solid white;
    border-radius: 25px;

    text-align: center;
    font-size: 30px;
    color:white;
}

.link-text
{
    text-decoration: none;
}