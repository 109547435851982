/* Chat Input Field */

.chatinputfield
{
    margin:auto;
    height: auto;
    width: 90%;

    border: 5px solid rgb(30, 30, 30);
    border-radius: 25px;

    text-align: center;
}