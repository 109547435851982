/* Chat Control */

.chatcontrol
{
    background-color: rgb(30, 30, 30);
    
    margin:auto;
    height: auto;
    width: 90%;

    border: 2px solid white;
    border-radius: 25px;

    text-align: center;
    font-size: 30px;
    color:white;
}